<template>
	<div>
		<!-- 催收专属 -->
		<template v-if="pageState.fromPath.indexOf('collections') != -1">
			<settlement-model
				v-if="lengingInfo.loanStatus < 1004"
				title="Early Settlement Form"
				:type="0"
				:onlyDownload="true"
				:lengingInfo="lengingInfo"
				style="margin-right: 10px"
			></settlement-model>
		</template>
		<!-- 财务专属 -->
		<template v-if="pageState.fromPath.indexOf('collections') === -1">
			<a-space :size="20">
				<a-button type="primary" @click="uploadRepayment(0)" v-if="lengingInfo.loanStatus < 1004">Early Settlement</a-button>
				<a-button type="primary" class="purple-btn" @click="uploadRepayment(1)" v-if="lengingInfo.loanStatus < 1005">Auction Settlement</a-button>
			</a-space>
		</template>
		<div class="remark">tips: {{ $t('table.finance.cloumRepayment.tips2') }}</div>

		<upload-repayment ref="uploadRepayment" @repaymentsInitTable="repaymentsInitTable"></upload-repayment>
	</div>
</template>
<script>
import { mapGetters } from 'vuex'
import SettlementModel from '@/views/businessComponents/SettlementModel'
import UploadRepayment from '@/views/businessComponents/UploadRepayment.vue'
export default {
	components: { SettlementModel, UploadRepayment },
	props: ['lengingInfo'],
	data() {
		return {

		}
	},
	computed: {
		...mapGetters(['pageState']),
	},
	methods: {
		uploadRepayment(type) {
			let repayType = type === 0?'repaymentEntryEarly': 'repaymentEntryAuction'
			this.$refs.uploadRepayment.uploadRepayment(this.lengingInfo.loanId, null, repayType)
		},
		repaymentsInitTable(){
			this.$message.success('Success')
			location.reload()
		}
	
	}
}
</script>
<style scoped lang="less">
.remark {
	padding: 10px 0;
	font-size: 16px;
	color: #f56c6c;
}
.ant-input,
.ant-input-number,
.ant-select {
	width: 160px;
}
.purple-btn {
	background-color: blueviolet;
	border-color: blueviolet;
}
</style>
