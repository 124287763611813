<template>
	<a-card size="small">
		<template v-if="loanType <5 || loanType==7">
			<span slot="title">
				{{ $t(`table.finance.lenging.repayTracking`) }}
				<a-button type="primary" @click="loanStatementVisible = true" style="margin-left: 20px">Download Loan Statement</a-button>
				<a-button v-if="pageState.fromPath.indexOf('collections') != -1" type="primary" @click="exportWordDocx" style="margin-left: 20px"
					>Download Repayment Schedule</a-button
				>
				<a-button type="primary" @click="uploadRepayment" v-if="pageState.fromPath.indexOf('collections') != -1 && lengingInfo.loanStatus < 1004" style="margin-left: 20px">Early Settlement</a-button>
				<upload-repayment ref="uploadRepayment" @repaymentsInitTable="repaymentsInitTable"></upload-repayment>
				<!-- <settlement-model
					v-if="pageState.fromPath.indexOf('collections') != -1"
					title="Early Settlement"
					btnColor="orange"
					:onlyPreview="true"
					:type="1"
					:lengingInfo="lengingInfo"
					style="margin-left: 20px"
				></settlement-model> -->
				<a-button v-if="pageState.fromPath === '/home/finance/repaymentEntrty' && lengingInfo.loanStatus < 1005" type="primary" @click="LuFee" style="margin-left: 20px">{{
					$t('table.finance.customerFee.enterFees')
				}}</a-button>
			</span>
		</template>
		<a-table  style="font-size: 12px" :columns="repayColumns" size="small" :rowKey="(record) => record.id" :dataSource="repayPlanDataSource" :pagination="false" :scroll="{ x: true }" :expand-column-width="100"  v-if="loanType <5 || loanType==7"  bordered>
			<span slot="statusId" slot-scope="obj">
				{{ obj.installments == 'Total'?'-':(obj.statusId == 2?'Seltted': 'Unseltted') }}
			</span>
			<b slot="title">
				{{
					!$Util.isEmpty(lengingInfo.loanType) ? $t(`table.applyInfo.financialLabel.loanMap.${lengingInfo.loanType}`) : 'logbook Loan/Asset Finance'
				}}
				<a-button
					v-if="pageState.fromPath.indexOf('finance') != -1 && lengingInfo.loanStatus < 1005"
					type="primary"
					@click="showDueDateModal"
					style="margin-left: 20px"
					:disabled="lengingInfo.hadChangeDueDay === 1"
					>Change due date</a-button
				>
				<!-- <a-button
					v-if="this.lengingInfo.loanType === 4 && pageState.fromPath.indexOf('finance') != -1"
					type="primary"
					@click="interestModal.show = true"
					style="margin-left: 20px"
					>Change interest</a-button
				> -->
			</b>
			<span slot="title2">
				<p class="p_list">
					{{ $t('table.finance.cloumRepayment.should') }}
				</p>
				<p class="p_list">
					{{ $t('table.finance.cloumRepayment.already') }}
				</p>
				<p class="p_list">
					Waiving Amount
				</p>
				<p class="p_list">
					Total Outstanding
				</p>
				<p class="p_list">
					Total Dafult
				</p>
			</span>
			<span slot="other" slot-scope="obj">
				<p class="link" @click="ShowModal(obj, 'Due')">{{ Number(obj.otherFeeDueTal).toFixed(2) }}</p>
				<p class="link" @click="ShowModal(obj, 'Paid')">{{ Number(obj.otherFeePaidTal).toFixed(2) }}</p>
				<p>{{ Number(obj.otherFeeExtraPaid).toFixed(2) }}</p>
				<p class="link" @click="ShowModal(obj, 'Outstanding')">{{ Number(obj.otherFeeOutstandingTal).toFixed(2) }}</p>
				<p class="link" @click="ShowModal(obj, 'Dafult')">{{ Number(obj.otherFeeDafultTal).toFixed(2) }}</p>
			</span>
			<a-table slot="expandedRowRender" slot-scope="record"  style="font-size: 12px" :columns="repayRecordColumns" size="small" :rowKey="(record, index) => index" :dataSource="record.details" :showHeader="false"  :scroll="{ x: true }" :pagination="false" bordered >
					<span slot="title2">
						<p class="p_list">
							{{ $t('table.finance.cloumRepayment.already') }}
						</p>
						<p class="p_list">
							Waiving Amount
						</p>
					</span>
					<span slot="other" slot-scope="obj2">
						<p>{{ Number(obj2.otherFeePaidTal).toFixed(2) }}</p>
						<p>{{ Number(obj2.otherFeeExtraPaid).toFixed(2) }}</p>
					</span>
					<span slot="paymentId" slot-scope="obj2">
						<p class="link" @click="ShowPaymentDatail(obj2.paymentId)">{{ obj2.paymentId }}</p>
					</span>
			</a-table>
		</a-table>
		<a-table style="font-size: 12px; margin: 20px 0" :columns="insuColumns" size="small" :rowKey="(record) => record.id" :dataSource="insurInfo" :pagination="false" v-if="loanType == 8" bordered>
			<b slot="title">{{ $t(`table.applyInfo.financialLabel.insurancePreFund`) }}</b>
			<span slot="title2">
				<p class="p_list">
					{{ $t('table.finance.cloumRepayment.should') }}
				</p>
				<p class="p_list">
					{{ $t('table.finance.cloumRepayment.already') }}
				</p>
				<p class="p_list">
					Total Outstanding
				</p>
				<p class="p_list">
					{{ $t('table.finance.cloumRepayment.tobe') }}
				</p>
			</span>
			<span slot="statusId" slot-scope="obj">
				{{ obj.installments == 'Total'?'-':(obj.statusId == 2?'Seltted': 'Unseltted') }}
			</span>
			<a-table slot="expandedRowRender" slot-scope="record"  style="font-size: 12px" :columns="insuRecordColumns" size="small" :rowKey="(record, index) => index" :dataSource="record.details" :pagination="false"  bordered :showHeader="false">
				<span slot="title2">
					<p class="p_list">
						{{ $t('table.finance.cloumRepayment.already') }}
					</p>
				</span>
				<span slot="paymentId" slot-scope="obj2">
					<p class="link" @click="ShowPaymentDatail(obj2.paymentId)">{{ obj2.paymentId }}</p>
				</span>
			</a-table>
		</a-table>
		<a-table style="font-size: 12px; margin: 10px 0" :columns="insuColumns2" size="small" :rowKey="(record) => record.id" :dataSource="emergencyInfo" :pagination="false"  bordered v-if="loanType == 5 || loanType == 6">
			<b slot="title">
				{{ !$Util.isEmpty(loanType) ? $t(`table.applyInfo.financialLabel.loanMap.${loanType}`) : 'logbook Loan/Asset Finance' }}
			</b>
			<span slot="title2">
				<p class="p_list">
					{{ $t('table.finance.cloumRepayment.should') }}
				</p>
				<p class="p_list">
					{{ $t('table.finance.cloumRepayment.already') }}
				</p>
				<p class="p_list">
					Total Outstanding
				</p>
				<p class="p_list">
					{{ $t('table.finance.cloumRepayment.tobe') }}
				</p>
			</span>
			<span slot="statusId" slot-scope="obj">
				{{ obj.installments == 'Total'?'-':(obj.statusId == 2?'Seltted': 'Unseltted') }}
			</span>
			<a-table slot="expandedRowRender" slot-scope="record"  style="font-size: 12px" :columns="insuRecordColumns2" size="small" :rowKey="(record, index) => index" :dataSource="record.details" :pagination="false"  bordered :showHeader="false">
				<span slot="title2">
					<p class="p_list">
						{{ $t('table.finance.cloumRepayment.already') }}
					</p>
				</span>
				<span slot="paymentId" slot-scope="obj2">
					<p class="link" @click="ShowPaymentDatail(obj2.paymentId)">{{ obj2.paymentId }}</p>
				</span>
			</a-table>
		</a-table>

		<a-table v-if="loanType <5 || loanType==7" :columns="prePaymentColumns" size="small" :rowKey="(record, index) => index" :dataSource="preRepays" :pagination="false" bordered class="border-right" style="margin-top: 10px;">
			<b slot="title">Pre-Payment Details</b>
			<span slot="receipt" slot-scope="obj">
				<template v-if="obj.transactionFiles">
					<viewer v-if="['png', 'jpg', 'jpeg', 'gif'].includes(obj.transactionFiles.split('?')[0].split('.').pop().toLowerCase())">
						<img :src="$Util.getObsImageUrl($store.state.pageState.authObj, obj.transactionFiles)" alt="" width="100" height="50" />
					</viewer>
					<a v-else :href="$Util.getObsImageUrl($store.state.pageState.authObj, obj.transactionFiles)" target="_blank">{{
						obj.transactionFiles.split('/')[obj.transactionFiles.split('/').length - 1]
					}}</a>
				</template>
			</span>
		</a-table>

		<a-table v-if="loanType <5 || loanType==7" :columns="detailsColumns" size="small" :rowKey="(record, index) => index" :dataSource="overpaidDetail.repays" :pagination="false" bordered class="border-right" style="margin-top: 10px;">
			<b slot="title">{{ $t(`table.applyInfo.financialLabel.overpaymentDetails`) }}</b>
			<span slot="receipt" slot-scope="obj">
				<template v-if="obj.transactionFiles">
					<viewer v-if="['png', 'jpg', 'jpeg', 'gif'].includes(obj.transactionFiles.split('?')[0].split('.').pop().toLowerCase())">
						<img :src="$Util.getObsImageUrl($store.state.pageState.authObj, obj.transactionFiles)" alt="" width="100" height="50" />
					</viewer>
					<a v-else :href="$Util.getObsImageUrl($store.state.pageState.authObj, obj.transactionFiles)" target="_blank">{{
						obj.transactionFiles.split('/')[obj.transactionFiles.split('/').length - 1]
					}}</a>
				</template>
				<template v-else-if="obj.transactionFiless">
					<span v-for="(img, index) in obj.transactionFiless" :key="index">
						<viewer v-if="['png', 'jpg', 'jpeg', 'gif'].includes(img.split('?')[0].split('.').pop().toLowerCase())">
							<img :src="$Util.getObsImageUrl($store.state.pageState.authObj, img)" alt="" width="100" height="50" />
						</viewer>
						<a v-else :href="$Util.getObsImageUrl($store.state.pageState.authObj, img)" target="_blank">{{
							img.split('/')[img.split('/').length - 1]
						}}</a>
						<br />
					</span>
				</template>
				<template v-else>-</template>
			</span>
		</a-table>

		<a-table style="font-size: 12px; margin-top: 10px" :columns="(loanType <5 || loanType==7)?chargeRecordCloumns:chargeRecordCloumns2"
			size="small" :rowKey="(record, index) => index" :dataSource="(loanType <5 || loanType==7)?feeEvents:(loanType==8)?insuranceFeeEventsList:emergencyFeeEventsList " :pagination="false" bordered >
			<b slot="title">
				Penalty & Other Charges Records
			</b>
			<span slot="fee" slot-scope="obj">
				<p class="link" @click="showOtherCharges(obj)" v-if="obj.type.value == 8">{{ $t(`table.finance.lenging.typeMap.${obj.type.value}`) }}</p>
				<p v-else>{{ $t(`table.finance.lenging.typeMap.${obj.type.value}`) }}</p>
			</span>
			<span slot="installments" slot-scope="obj">
				<p v-if="obj.type.value == 8">
					{{ GetInstallments(obj) }}
				</p>
				<p v-else>{{obj.installments}}</p>
			</span>
			<span slot="receipt" slot-scope="obj">
				<template v-if="obj.type.value == 8">
					<p v-if="obj.certificates">
						<span v-for="(img, index) in obj.certificates" :key="index">
							<viewer v-if="['png', 'jpg', 'jpeg', 'gif'].includes(img.split('?')[0].split('.').pop().toLowerCase())">
								<img :src="$Util.getObsImageUrl($store.state.pageState.authObj, img)" alt="" width="100" height="50" />
							</viewer>
							<a v-else :href="$Util.getObsImageUrl($store.state.pageState.authObj, img)" target="_blank">{{
								img.split('/')[img.split('/').length - 1]
							}}</a>
							<br />
						</span>
					</p>
					<p v-else>
						<span v-for="(img, index) in obj.details" :key="index">
							<span v-if="img.voucher">
								<viewer v-if="['png', 'jpg', 'jpeg', 'gif'].includes(img.voucher.split('?')[0].split('.').pop().toLowerCase())">
									<img :src="$Util.getObsImageUrl($store.state.pageState.authObj, img.voucher)" alt="" width="100" height="50" />
								</viewer>
								<a v-else :href="$Util.getObsImageUrl($store.state.pageState.authObj, img.voucher)" target="_blank">{{
									img.voucher.split('/')[img.voucher.split('/').length - 1]
								}}</a>
								<br />
							</span>
						</span>
					</p>
				</template>
				<template v-else>
					<span v-if="obj.certificate">
							<viewer v-if="['png', 'jpg', 'jpeg', 'gif'].includes(obj.certificate.split('?')[0].split('.').pop().toLowerCase())">
								<img :src="$Util.getObsImageUrl($store.state.pageState.authObj, obj.certificate)" alt="" width="100" height="50" />
							</viewer>
							<a v-else :href="$Util.getObsImageUrl($store.state.pageState.authObj, obj.certificate)" target="_blank">{{
								obj.certificate.split('/')[obj.certificate.split('/').length - 1]
							}}</a>
							<br />
						</span>
				</template>
			</span>

			<span slot="action" slot-scope="obj">
				<a @click="SeePenaltyModal(obj)" v-if="obj.type.value != 1 && obj.type.value != 0" :disabled="CanAction(obj)">Amend Period</a>
				<a-divider type="vertical" v-if="obj.type.value != 1 && obj.type.value != 0" />
				<a @click="deletePenalty(obj)" v-if="obj.type.value != 1 && obj.type.value != 0" :disabled="CanAction(obj)">Delete</a>
			</span>
		</a-table>

		<a-table v-if="loanType <5 || loanType==7"  style="font-size: 12px; margin-top: 10px" :columns="repaymentColumns"
			size="small" :rowKey="(record, index) => index" :dataSource="repayDataSource" :pagination="false" bordered >
			<b slot="title">
				Consolidated Repayment Details
			</b>
			<span slot="otherFeePaid" slot-scope="obj">
				{{ obj.otherFeeExtraPaid }} / {{ obj.otherFeeDetails }}
			</span>
		</a-table>

		<a-table v-if="loanType == 8"  style="font-size: 12px; margin-top: 10px" :columns="insuranceColumns" size="small" :rowKey="(record, index) => index" :dataSource="insuRepayDataSource" :pagination="false" bordered >
			<b slot="title">
				Consolidated Repayment Details
			</b>
			<span slot="otherFeePaid" slot-scope="obj">
				{{ obj.otherFeeDetails }}
			</span>
		</a-table>

		<a-table v-if="loanType == 5 || loanType == 6"  style="font-size: 12px; margin-top: 10px" :columns="emergencyColumns" size="small" :rowKey="(record, index) => index" :dataSource="emergencyRepayDataSource" :pagination="false" bordered >
			<b slot="title">
				Consolidated Repayment Details
			</b>
			<span slot="otherFeePaid" slot-scope="obj">
				{{ obj.otherFeeDetails }}
			</span>
		</a-table>

		<a-descriptions bordered :title="$t('table.applyInfo.financialLabel.loanType')" size="small">
			<a-descriptions-item :label="$t('table.applyInfo.financialLabel.loanType')">
				{{ $t(`table.applyInfo.financialLabel.loanMap.${loanType}`) }}
			</a-descriptions-item>
		</a-descriptions>

		<a-modal v-drag-modal v-model="lookFeeModal.show" :footer="false" width="800px" title="Other Charges">
			<a-space :size="40">
				<span>Period: {{ lookFeeModal.installments }}</span>
				<span>Status: {{ lookFeeModal.statusId == 2?'Seltted': 'Unseltted' }}</span>
			</a-space>
			<a-table
				style="font-size: 12px; margin-top: 10px;"
				:columns="lookFeeModal.columns"
				size="small"
				:rowKey="(record) => record.id"
				:dataSource="lookFeeModal.dataSource"
				:pagination="false"
				bordered
			></a-table>
		</a-modal>
		<a-modal v-model="loanStatementVisible" title="Download Loan Statement" @ok="onExport">
			<a-checkbox-group v-model="checkedStatement" :options="optionsStatement" />
		</a-modal>
		<a-modal v-drag-modal v-model="FeeModal.show" :title="$t('menus.financeMenu.customerFee')" :destroyOnClose="true" width="60%" :footer="false">
			<a-form-model :label-col="{ span: 5 }" :wrapper-col="{ span: 19 }" class="from">
				<a-form-model-item>
					<a-select v-model="FeeModal.type" @change="ChangeType">
						<a-select-option :value="item.value" v-for="item in FeeModal.selectList" :disabled="item.disabled" :key="item.value">{{
							$t(`table.finance.customerFee.tabs.${item.value}`)
						}}</a-select-option>
					</a-select>
				</a-form-model-item>
				<a-card :title="$t('table.finance.customerFee.tabs.1')" size="small" v-if="FeeModal.type == 1">
					<a-form-model-item :label="$t('table.finance.customerFee.fee')">
						<a-input-number v-model="FeeModal.amount" :precision="0" />
					</a-form-model-item>
					<a-form-model-item :label="$t('table.finance.customerFee.periodInput')">
						<a-select v-model="FeeModal.checkInstallments">
							<a-select-option :value="item" v-for="item in installmentsOptions2" :key="item">{{ item }}</a-select-option>
						</a-select>
					</a-form-model-item>
					<a-form-model-item :label="$t('table.finance.customerFee.bankName')">
						<a-input v-model="FeeModal.bankName" />
					</a-form-model-item>
					<a-form-model-item :label="$t('table.finance.customerFee.voucher')">
						<UploadPreviewFile :uploadFilePath.sync="FeeModal.certificate" :folderPath="FeeModal.id"></UploadPreviewFile>
					</a-form-model-item>
				</a-card>
				<a-card :title="$t(`table.finance.customerFee.tabs.${FeeModal.type}`)" size="small" v-if="FeeModal.type == 3 || FeeModal.type == 2">
					<a-form-model-item :label="$t('table.finance.customerFee.fee')">
						<a-input-number v-model="FeeModal.amount" :precision="0" />
					</a-form-model-item>
					<a-form-model-item :label="$t('table.finance.customerFee.periodInput')">
						<a-select v-model="FeeModal.checkInstallments">
							<a-select-option :value="item" v-for="item in installmentsOptions2" :key="item">{{ item }}</a-select-option>
						</a-select>
					</a-form-model-item>
					<a-form-model-item :label="$t('table.finance.customerFee.invoice')">
						<UploadPreviewFile :uploadFilePath.sync="FeeModal.certificate" :folderPath="FeeModal.id"></UploadPreviewFile>
					</a-form-model-item>
				</a-card>
				<a-card :title="$t('table.finance.customerFee.tabs.4')" size="small" v-if="FeeModal.type == 4">
					<a-form-model-item>
						<a-button type="primary" @click="AddData" style="margin-left: 20px">{{ $t('page.add') }}</a-button>
					</a-form-model-item>
					<a-table
						style="font-size: 12px"
						:columns="FeeModal.columns"
						size="small"
						:rowKey="(record) => record.id"
						:dataSource="FeeModal.dataSource"
						:pagination="false"
						bordered
					>
						<span slot="detail" slot-scope="obj">
							<a-input v-model="obj.feeName" style="width: 100%" />
						</span>
						<span slot="amount" slot-scope="obj">
							<a-input-number v-model="obj.feeAmount" style="width: 100%" :precision="0" />
						</span>
						<span slot="installments" slot-scope="obj">
							<a-select v-model="obj.installments" style="width: 100%">
								<a-select-option :value="item" v-for="item in installmentsOptions2" :key="item">{{ item }}</a-select-option>
							</a-select>
						</span>
						<span slot="voucher" slot-scope="obj">
							<UploadPreviewFile :uploadFilePath.sync="obj.voucher" :folderPath="FeeModal.id"></UploadPreviewFile>
						</span>
						<span slot="action2" slot-scope="text, obj, index">
							<a @click="Del(index)">{{ $t('page.delete') }}</a>
						</span>
					</a-table>
				</a-card>
				<a-form-model-item>
					<a-button type="primary" @click="SaveFrom">
						{{ $t('page.submit') }}
					</a-button>
				</a-form-model-item>
			</a-form-model>
		</a-modal>
		<a-modal v-model="dueDateModal.show" title="Change due date" @ok="changeDueDay" width="600px">
			<a-form-model-item label="Please select the peroid you want to start changing due date" :label-col="{ span: 24 }" labelAlign="left">
				<a-select v-model="dueDateModal.installments" style="width: 200px">
					<a-select-option :value="item" v-for="item in dueDateInstList" :key="item">{{ item }}</a-select-option>
				</a-select>
				<a-date-picker
					v-model="dueDateModal.changedDueDate"
					:disabled-date="disabledDate"
					format="YYYY-MM-DD HH:mm:ss"
					:show-time="{ defaultValue: moment('00:00:00', 'HH:mm:ss') }"
					style="margin-left: 10px"
				/>
			</a-form-model-item>
			<p><b>Notes:</b>5% of the principal will be charged for the selected period.</p>
			<!-- <a-form-model-item label="Give the 5% of principal as a charge to which period" :label-col="{ span: 24 }" labelAlign="left">
				<a-select v-model="dueDateModal.feePeriod" style="width: 200px">
					<a-select-option :value="item" v-for="item in dueDateInstList" :key="item">{{ item }}</a-select-option>
				</a-select>
			</a-form-model-item> -->
			<a-form-model-item label="Upload Form" :label-col="{ span: 24 }" labelAlign="left">
				<UploadPreviewFile :uploadFilePath.sync="dueDateModal.receipt" :folderPath="$route.query.id"></UploadPreviewFile>
			</a-form-model-item>
		</a-modal>
		<a-modal v-model="interestModal.show" title="Change interest" @ok="changeInterest" width="600px">
			<a-form-model-item label="Please choose the period to change the lnterest rate" :label-col="{ span: 24 }" labelAlign="left">
				<a-select v-model="interestModal.installments" style="width: 200px">
					<a-select-option :value="item" v-for="item in laveInstList" :key="item">{{ item }}</a-select-option>
				</a-select>
			</a-form-model-item>
			<a-form-model-item label="Please inpuy the new lnterest rate" :label-col="{ span: 24 }" labelAlign="left">
				<a-input-number
					v-model="interestModal.interest"
					:precision="4"
					:step="0.01"
					:min="0"
					:formatter="(value) => `${value * 100}%`"
					:parser="(value) => Number(value.replace('%', '')) / 100"
				/>
			</a-form-model-item>
		</a-modal>

		<a-modal v-drag-modal v-model="repaymentDatils.visible" title="Repayment Details" :destroyOnClose="true" width="50%" :footer="false">
			<a-form-model :label-col="{ span: 8 }" :wrapper-col="{ span: 16 }" class="from">
				<a-form-model-item label="Receipt No."> {{ repaymentDatils.paymentId }} </a-form-model-item>
				<a-form-model-item label="Completion Time"> {{ repaymentDatils.transactionDate }} </a-form-model-item>
				<a-form-model-item label="Repayment Amount"> {{ repaymentDatils.paymentAmount.toFixed(2) }} </a-form-model-item>
				<a-form-model-item label="Repayment Type"> {{ $t(`table.finance.repayment.payTypeMap.${repaymentDatils.transactionType}`) }} </a-form-model-item>
				<a-form-model-item label="Mobile / Account Number"> {{ repaymentDatils.mobileNo }} </a-form-model-item>
			</a-form-model>
		</a-modal>

		<a-modal v-drag-modal v-model="otherChargeModal.visible" :footer="false" width="800px" title="Other Charges">
			<a-table
				style="font-size: 12px; margin-top: 10px;"
				:columns="otherChargeModal.cloumns"
				size="small"
				:rowKey="(record) => record.id"
				:dataSource="otherChargeModal.details"
				:pagination="false"
				bordered
			>
				<span slot="receipt" slot-scope="obj">
					<template>
						<span v-if="obj.voucher">
							<viewer v-if="['png', 'jpg', 'jpeg', 'gif'].includes(obj.voucher.split('?')[0].split('.').pop().toLowerCase())">
								<img :src="$Util.getObsImageUrl($store.state.pageState.authObj, obj.voucher)" alt="" width="100" height="50" />
							</viewer>
							<a v-else :href="$Util.getObsImageUrl($store.state.pageState.authObj, obj.voucher)" target="_blank">{{
								obj.voucher.split('/')[obj.voucher.split('/').length - 1]
							}}</a>
						</span>
					</template>
				</span>
			</a-table>
			<a-space>
				<p v-if="otherChargeModal.certificates">
					<span v-for="(img, index) in otherChargeModal.certificates" :key="index">
						<viewer v-if="['png', 'jpg', 'jpeg', 'gif'].includes(img.split('?')[0].split('.').pop().toLowerCase())">
							<img :src="$Util.getObsImageUrl($store.state.pageState.authObj, img)" alt="" width="100" height="50" />
						</viewer>
						<a v-else :href="$Util.getObsImageUrl($store.state.pageState.authObj, img)" target="_blank">{{
							img.split('/')[img.split('/').length - 1]
						}}</a>
					</span>
				</p>
			</a-space>
		</a-modal>

		<a-modal v-drag-modal v-model="penaltyModalShow" width="600px" :title="penaltyType === 0 || penaltyType === 1 ? 'Recalculate Penalty' : 'Amend Period'" okText="Confirm" @ok="handModalOk" >
			<div v-if="penaltyType === 0 || penaltyType === 1">
				<p>Please select the time that the penalty should be generated, then it will be recalculated automatically:</p>
				<p><a-date-picker v-model="penaltyTime" format="YYYY-MM-DD HH:mm:ss" show-time /></p>
			</div>
			<div v-else>
				<p>Please choose the period you want to input:</p>
				<p>
					<a-select v-model="checkInstallments" style="width: 200px">
						<a-select-option :value="item" v-for="item in installmentsOptions" :key="item">{{ item }}</a-select-option>
					</a-select>
				</p>
			</div>
		</a-modal>

		<html-to-canvas type="finance" :canvasDataSource="canvasDataSource" :loanType="lengingInfo.loanType"  ref="financeLoanStatement"></html-to-canvas>
		<html-to-canvas
			type="collections"
			:canvasDataSource="canvasDataSource"
			:loanStatus="lengingInfo.loanStatus"
			ref="collectionsLoanStatement"
		></html-to-canvas>
	</a-card>
</template>
<script>
import { mapGetters } from 'vuex'
import { exportWordDocx } from '@/utils/exportWordDocx'
import { apiFeeInput } from '@/api/check'
import { apiChangeDueDay, apiChangeInterest, apiRegeneratePenalty, apiModifyFee, apiDeleteFee } from '@/api/loan'
import { apiRepaymentSchedule } from '@/api/collections'
// import SettlementModel from '@/views/businessComponents/SettlementModel'
import moment from 'moment'
import UploadRepayment from '@/views/businessComponents/UploadRepayment.vue'
export default {
	components: {  UploadRepayment },
	props: ['lengingInfo', 'repayPlan', 'insurancePlans', 'emergencyPlans','loanType', 'repays', 'feeEvents', 'insuranceFeeEvents', 'emergencyFeeEvents', 'repaymentDetails', 'insuranceRepaymentDetails', 'emergencyRepaymentDetails', 'overpaidDetail', 'preRepays', 'additionalLoanId'],
	data() {
		return {
			canvasDataSource: [],
			lookFeeModal: {
				show: false,
				columns: [
					{
						// 费用金额
						title: this.$t('table.finance.customerFee.cloum.detail'),
						align: 'center',
						dataIndex: 'feeName'
					},
					{
						// 费用金额
						title: this.$t('table.finance.customerFee.cloum.amount'),
						align: 'center',
						dataIndex: 'amount',
						width: 100
					}
				],
				dataSource: [],
				statusId: 2,
				installments: 1
			},
			FeeModal: {
				selectList: [
					{ value: 1, disabled: false },
					{ value: 2, disabled: false },
					{ value: 3, disabled: false },
					{ value: 4, disabled: false }
				],
				show: false,
				id: '',
				type: 4,
				bankName: '',
				dataSource: [],
				columns: [
					{
						// 费用明细
						title: this.$t('table.finance.customerFee.cloum.detail'),
						scopedSlots: { customRender: 'detail' },
						align: 'center',
					},
					{
						// 金额
						title: this.$t('table.finance.customerFee.cloum.amount'),
						scopedSlots: { customRender: 'amount' },
						align: 'center',
					},
					{
						// 期数
						title: this.$t('table.finance.customerFee.periodInput'),
						scopedSlots: { customRender: 'installments' },
						align: 'center',
						width: '15%'
					},
					{
						// 凭证
						title: 'Voucher Upload',
						scopedSlots: { customRender: 'voucher' },
						align: 'center'
					},
					{
						// 操作
						title: this.$t('operate.operate'),
						scopedSlots: { customRender: 'action2' },
						align: 'center',
						width: '15%'
					}
				],
				amount: '',
				transactionTime: '',
				certificate: '',
				certificates: [],
				checkInstallments: '',
				installmentsNumbers: ''
			},
			repayPlanDataSource: [],
			insurancePlanDataSource: [],
			emergencyPlanDataSource: [],
			loanStatementVisible: false,
			checkedStatement: [],
			dueDateModal: {
				show: false,
				loanId: this.$route.query.id,
				installments: 1,
				changedDueDate: '',
				receipt: ''
			},
			interestModal: {
				show: false,
				loanId: this.$route.query.id,
				installments: 1,
				interest: 0.0108
			},
			repaymentDatils: {
				visible: false,
				paymentId: '',
				transactionType: 0,
				paymentAmount: 0.00,
				mobileNo: '',
				transactionDate: ''
			},
			repayDataSource: [],
			insuRepayDataSource: [],
			emergencyRepayDataSource: [],
			otherChargeModal: {
				visible: false,
				certificates: [],
				details: [],
				cloumns: [
					{
						title: 'Expense Details',
						align: 'center',
						dataIndex: 'feeName'
					},
					{
						title: 'Amount',
						align: 'center',
						dataIndex: 'amount'
					},
					{
						title: this.$t('table.finance.customerFee.periodInput'),
						align: 'center',
						dataIndex: 'installments'
					},
					{
						title: 'Vocher / Invoice',
						align: 'center',
						scopedSlots: { customRender: 'receipt' },
					},
				]
			},
			penaltyModalShow: false,
			penaltyTime: '',
			penaltyId: '',
			checkInstallments: null,
			penaltyType: null,
			insurInfo: []
		}
	},
	computed: {
		...mapGetters(['pageState']),
		NotRepaidInstallmentsOptions() {
			const arr = []
			this.repayPlan.forEach((element) => {
				if (element.statusId !== 0) {
					arr.push(element.installments)
				}
			})
			return arr
		},
		installmentsOptions() {
			const arr = []
			this.repayPlan.forEach((element) => {
				if (moment(new Date()).isBefore(element.duedate) && element.statusId == 0) {
					arr.push(element.installments)
				}
			})
			return arr
		},
		installmentsOptions2() {
			const arr = []
			this.repayPlan.forEach((element) => {
				// if (moment(new Date()).isBefore(element.duedate) && element.statusId != 2) {
				// 	arr.push(element.installments)
				// }
				if (element.statusId != 2) {
					arr.push(element.installments)
				}
			})
			return arr
		},
		dueDateInstList() {
			const dueDateInst = []
			this.repayPlan.forEach((element) => {
				if (moment(new Date()).isBefore(element.duedate) && element.statusId != 2) {
					dueDateInst.push(element.installments)
				}
			})
			return dueDateInst
		},
		laveInstList() {
			const dueDateInst = []
			this.repayPlan.forEach((element) => {
				dueDateInst.push(element.installments)
			})
			return dueDateInst
		},
		repayColumns() {
			const repayColumnsList = [
				{
					// 期数
					title: this.$t('table.finance.cloumRepayment.trem'),
					align: 'center',
					dataIndex: 'installments',
					width: 80,
				},
				{
					title: 'Scheduled Repayment Date',
					dataIndex: 'duedate',
					align: 'center',
					width: 100,
				},
				{
					title: 'Status',
					scopedSlots: { customRender: 'statusId' },
					align: 'center',
					width: 100,
				},
				{
					title: ' ',
					scopedSlots: { customRender: 'title2' },
					align: 'center',
					width: 180,
				},
				{
					// 本金
					title: this.$t('table.finance.cloumRepayment.amount'),
					align: 'center',
					customRender: (obj) => {
						return (
							<span>
								<p>{obj.principalDue.toFixed(2)}</p> <p>{obj.principalPaid.toFixed(2)}</p>
								<p>0.00</p> <p>{obj.principalOutstanding.toFixed(2)}</p><p>{obj.principalDafult.toFixed(2)}</p>
							</span>
						)
					},
					width: 100,
				},
				{
					// 其他
					title: this.$t('table.finance.cloumRepayment.other'),
					scopedSlots: { customRender: 'other' },
					align: 'center',
					width: 100,
				},
				{
					// 罚息
					title: this.$t('table.finance.cloumRepayment.penalty'),
					align: 'center',
					customRender: (obj) => {
						return (
							<span>
								<p>{obj.penaltyDue.toFixed(2)}</p> <p>{obj.penaltyPaid.toFixed(2)}</p>
								<p>{obj.penaltyExtraPaid.toFixed(2)}</p> <p>{obj.penaltyOutstanding.toFixed(2)}</p><p>{obj.penaltyDafult.toFixed(2)}</p>
							</span>
						)
					},
					width: 100,
				},
				{
					// 月度罚息
					title: this.$t('table.finance.cloumRepayment.monthly'),
					align: 'center',
					customRender: (obj) => {
						return (
							<span>
								<p>{obj.interestDue.toFixed(2)}</p> <p>{obj.interestPaid.toFixed(2)}</p>
								<p>{obj.interestExtraPaid.toFixed(2)}</p> <p>{obj.interestOutstanding.toFixed(2)}</p>
								<p>{obj.interestDafult.toFixed(2)}</p>
							</span>
						)
					},
					width: 100,
				},
				{
					title: 'Early Settlement Interest',
					align: 'center',
					customRender: (obj) => {
						return (
							<span>
								<p>{obj.earlyInterestDue.toFixed(2)}</p> <p>{obj.earlyInterestPaid.toFixed(2)}</p>
								<p>{obj.earlyInterestExtraPaid.toFixed(2)}</p> <p>{obj.earlyInterestOutstanding.toFixed(2)}</p><p>{obj.earlyInterestDafult.toFixed(2)}</p>
							</span>
						)
					},
					width: 100,
				},
				{
					// 总额
					title: this.$t('table.finance.cloumRepayment.sum'),
					align: 'center',
					customRender: (obj) => {
						return (
							<span>
								<p>{obj.totalDue.toFixed(2)}</p> <p>{obj.totalPaid.toFixed(2)}</p> <p>{obj.totalExtraPaid.toFixed(2)}</p>
								<p>{obj.totalOutstanding.toFixed(2)}</p>
								<p>{obj.totalDafult.toFixed(2)}</p>
							</span>
						)
					}
				}
			]
			if (this.lengingInfo.loanType === 4) {
				repayColumnsList.splice(5, 0, {
					// GPS费用
					title: this.$t('table.finance.cloumRepayment.gpsFee'),
					align: 'center',
					customRender: (obj) => {
						return (
							<span>
								<p>{obj.trackingFeeDue.toFixed(2)}</p> <p>{obj.trackingFeePaid.toFixed(2)}</p>
								<p>{obj.trackingFeeExtraPaid.toFixed(2)}</p> <p>{obj.trackingFeeOutstanding.toFixed(2)}</p>
								<p>{obj.trackingFeeDafult.toFixed(2)}</p>
							</span>
						)
					},
					width: 100,
				})
			}
			if (this.lengingInfo.loanType !== 3 && this.lengingInfo.loanType !== 4) {
				repayColumnsList.splice(
					5,
					0,
					{
						// GPS费用
						title: this.$t('table.finance.cloumRepayment.gpsFee'),
						align: 'center',
						customRender: (obj) => {
							return (
								<span>
									<p>{obj.trackingFeeDue.toFixed(2)}</p> <p>{obj.trackingFeePaid.toFixed(2)}</p>
									<p>{obj.trackingFeeExtraPaid.toFixed(2)}</p> <p>{obj.trackingFeeOutstanding.toFixed(2)}</p><p>{obj.trackingFeeDafult.toFixed(2)}</p>
								</span>
							)
						},
						width: 100,
					},
					{
						title: this.$t('table.applyInfo.loanApplyLabel.maintenanceFee'),
						align: 'center',
						customRender: (obj) => {
							return (
								<span>
									<p>{obj.maintenanceFeeDue.toFixed(2)}</p> <p>{obj.maintenanceFeePaid.toFixed(2)}</p>
									<p>{obj.maintenanceFeeExtraPaid.toFixed(2)}</p> <p>{obj.maintenanceFeeOutstanding.toFixed(2)}</p>
									<p>{obj.maintenanceFeeDafult.toFixed(2)}</p>
								</span>
							)
						},
						width: 100,
					}
				)
			}
			return repayColumnsList
		},
		repayRecordColumns() {
			const repayColumnsList2 = [
				{
					// 期数
					title: this.$t('table.finance.cloumRepayment.trem'),
					align: 'center',
					dataIndex: 'installments',
					width: 80,
				},
				{
					title: 'Scheduled Repayment Date',
					dataIndex: 'transactionTime',
					align: 'center',
					width: 100,
				},
				{
					title: 'Reference ID',
					scopedSlots: { customRender: 'paymentId' },
					align: 'center',
					width: 100,
				},
				{
					title: ' ',
					scopedSlots: { customRender: 'title2' },
					align: 'center',
					width: 180,
				},
				{
					// 本金
					title: this.$t('table.finance.cloumRepayment.amount'),
					align: 'center',
					customRender: (obj) => {
						return (
							<span>
								<p>{obj.principalPaid.toFixed(2)}</p>
								<p>0.00</p>
							</span>
						)
					},
					width: 100,
				},
				{
					// 其他
					title: this.$t('table.finance.cloumRepayment.other'),
					scopedSlots: { customRender: 'other' },
					align: 'center',
					width: 100,
				},
				{
					// 罚息
					title: this.$t('table.finance.cloumRepayment.penalty'),
					align: 'center',
					customRender: (obj) => {
						return (
							<span>
								<p>{obj.penaltyPaid.toFixed(2)}</p>
								<p>{obj.penaltyExtraPaid.toFixed(2)}</p>
							</span>
						)
					},
					width: 100,
				},
				{
					// 月度罚息
					title: this.$t('table.finance.cloumRepayment.monthly'),
					align: 'center',
					customRender: (obj) => {
						return (
							<span>
								<p>{obj.interestPaid.toFixed(2)}</p>
								<p>{obj.interestExtraPaid.toFixed(2)}</p>
							</span>
						)
					},
					width: 100,
				},
				{
					title: 'Early Settlement Interest',
					align: 'center',
					customRender: (obj) => {
						return (
							<span>
								<p>{obj.earlyInterestPaid.toFixed(2)}</p>
								<p>{obj.earlyInterestExtraPaid.toFixed(2)}</p>
							</span>
						)
					},
					width: 100,
				},
				{
					// 总额
					title: this.$t('table.finance.cloumRepayment.sum'),
					align: 'center',
					customRender: (obj) => {
						return (
							<span>
								<p>{obj.amount.toFixed(2)}</p>
								<p>{obj.extraAmount.toFixed(2)}</p>
							</span>
						)
					}
				}
			]
			if (this.lengingInfo.loanType === 4) {
				repayColumnsList2.splice(5, 0, {
					// GPS费用
					title: this.$t('table.finance.cloumRepayment.gpsFee'),
					align: 'center',
					customRender: (obj) => {
						return (
							<span>
								<p>{obj.trackingFeePaid.toFixed(2)}</p>
								<p>{obj.trackingFeeExtraPaid.toFixed(2)}</p>
							</span>
						)
					},
					width: 100,
				})
			}
			if (this.lengingInfo.loanType !== 3 && this.lengingInfo.loanType !== 4) {
				repayColumnsList2.splice(
					5,
					0,
					{
						// GPS费用
						title: this.$t('table.finance.cloumRepayment.gpsFee'),
						align: 'center',
						customRender: (obj) => {
							return (
								<span>
									<p>{obj.trackingFeePaid.toFixed(2)}</p>
									<p>{obj.trackingFeeExtraPaid.toFixed(2)}</p>
								</span>
							)
						},
						width: 100,
					},
					{
						title: this.$t('table.applyInfo.loanApplyLabel.maintenanceFee'),
						align: 'center',
						customRender: (obj) => {
							return (
								<span>
									<p>{obj.maintenanceFeePaid.toFixed(2)}</p>
									<p>{obj.maintenanceFeeExtraPaid.toFixed(2)}</p>
								</span>
							)
						},
						width: 100,
					}
				)
			}
			return repayColumnsList2
		},
		insuColumns() {
			return [
				{
					title: this.$t('table.finance.cloumRepayment.trem'),
					align: 'center',
					dataIndex: 'installments',
					width: 60,
				},
				{
					title: 'Scheduled Repayment Date',
					dataIndex: 'duedate',
					align: 'center',
					width: 100,
				},
				{
					title: 'Status',
					scopedSlots: { customRender: 'statusId' },
					align: 'center',
					width: 100,
				},
				{
					title: ' ',
					scopedSlots: { customRender: 'title2' },
					align: 'center',
					width: 180,
				},
				{
					title: this.$t('table.finance.cloumRepayment.amount'),
					align: 'center',
					customRender: (obj) => {
						return (
							<span>
								<p>{obj.principalDue.toFixed(2)}</p>
								<p>{obj.principalPaid.toFixed(2)}</p>
								<p>{obj.principalOutstanding.toFixed(2)}</p>
								<p>{obj.principalDefault.toFixed(2)}</p>
							</span>
						)
					},
					width: 100,
				},
				{
					title: this.$t('table.finance.cloumRepayment.penalty'),
					align: 'center',
					customRender: (obj) => {
						return (
							<span>
								<p>{obj.penaltyDue.toFixed(2)}</p>
								<p>{obj.penaltyPaid.toFixed(2)}</p>
								<p>{obj.penaltyOutstanding.toFixed(2)}</p>
								<p>{obj.penaltyDefault.toFixed(2)}</p>
							</span>
						)
					},
					width: 100,
				},
				{
					title: this.$t('table.finance.cloumRepayment.monthly'),
					align: 'center',
					customRender: (obj) => {
						return (
							<span>
								<p>{obj.interestDue.toFixed(2)}</p>
								<p>{obj.interestPaid.toFixed(2)}</p>
								<p>{obj.interestOutstanding.toFixed(2)}</p>
								<p>{obj.interestDefault.toFixed(2)}</p>
							</span>
						)
					},
					width: 100,
				},
				{
					title: this.$t('table.finance.cloumRepayment.sum'),
					align: 'center',
					customRender: (obj) => {
						return (
							<span>
								<p>{obj.totalDue.toFixed(2)}</p>
								<p>{obj.totalPaid.toFixed(2)}</p>
								<p>{obj.totalOutstanding.toFixed(2)}</p>
								<p>{obj.totalDefault.toFixed(2)}</p>
							</span>
						)
					}
				}
			]
		},
		insuColumns2() {
			return [
				{
					title: this.$t('table.finance.cloumRepayment.trem'),
					align: 'center',
					dataIndex: 'installments',
					width: 60,
				},
				{
					title: 'Scheduled Repayment Date',
					dataIndex: 'duedate',
					align: 'center',
					width: 100,
				},
				{
					title: 'Status',
					scopedSlots: { customRender: 'statusId' },
					align: 'center',
					width: 100,
				},
				{
					title: ' ',
					scopedSlots: { customRender: 'title2' },
					align: 'center',
					width: 180,
				},
				{
					title: this.$t('table.finance.cloumRepayment.amount'),
					align: 'center',
					customRender: (obj) => {
						return (
							<span>
								<p>{obj.principalDue.toFixed(2)}</p>
								<p>{obj.principalPaid.toFixed(2)}</p>
								<p>{obj.principalOutstanding.toFixed(2)}</p>
								<p>{obj.principalDefault.toFixed(2)}</p>
							</span>
						)
					},
					width: 100,
				},
				{
					title: this.$t('table.finance.cloumRepayment.penalty'),
					align: 'center',
					customRender: (obj) => {
						return (
							<span>
								<p>{obj.penaltyDue.toFixed(2)}</p>
								<p>{obj.penaltyPaid.toFixed(2)}</p>
								<p>{obj.penaltyOutstanding.toFixed(2)}</p>
								<p>{obj.penaltyDefault.toFixed(2)}</p>
							</span>
						)
					},
					width: 100,
				},
				{
					title: this.$t('table.finance.cloumRepayment.monthly'),
					align: 'center',
					customRender: (obj) => {
						return (
							<span>
								<p>{obj.interestDue.toFixed(2)}</p>
								<p>{obj.interestPaid.toFixed(2)}</p>
								<p>{obj.interestOutstanding.toFixed(2)}</p>
								<p>{obj.interestDefault.toFixed(2)}</p>
							</span>
						)
					},
					width: 100,
				},
				{
					title: 'Early Settlement Interest',
					align: 'center',
					customRender: (obj) => {
						return (
							<span>
								<p>{obj.earlyInterestDue.toFixed(2)}</p>
								<p>{obj.earlyInterestPaid.toFixed(2)}</p>
								<p>{obj.earlyInterestOutstanding.toFixed(2)}</p>
								<p>{obj.earlyInterestDafult.toFixed(2)}</p>
							</span>
						)
					},
					width: 100,
				},
				{
					title: this.$t('table.finance.cloumRepayment.sum'),
					align: 'center',
					customRender: (obj) => {
						return (
							<span>
								<p>{obj.totalDue.toFixed(2)}</p>
								<p>{obj.totalPaid.toFixed(2)}</p>
								<p>{obj.totalOutstanding.toFixed(2)}</p>
								<p>{obj.totalDefault.toFixed(2)}</p>
							</span>
						)
					}
				}
			]
		},
		insuRecordColumns() {
			return [
				{
					title: this.$t('table.finance.cloumRepayment.trem'),
					align: 'center',
					dataIndex: 'installments2',
					width: 60,
				},
				{
					title: 'Scheduled Repayment Date',
					dataIndex: 'transactionTime',
					align: 'center',
					width: 100,
				},
				{
					title: 'Reference ID',
					scopedSlots: { customRender: 'paymentId' },
					align: 'center',
					width: 100,
				},
				{
					title: ' ',
					scopedSlots: { customRender: 'title2' },
					align: 'center',
					width: 180,
				},
				{
					title: this.$t('table.finance.cloumRepayment.amount'),
					align: 'center',
					customRender: (obj) => {
						return (
							<span>
								<p>{obj.principalPaid.toFixed(2)}</p>
							</span>
						)
					},
					width: 100,
				},
				{
					title: this.$t('table.finance.cloumRepayment.penalty'),
					align: 'center',
					customRender: (obj) => {
						return (
							<span>
								<p>{obj.penaltyPaid.toFixed(2)}</p>
							</span>
						)
					},
					width: 100,
				},
				{
					title: this.$t('table.finance.cloumRepayment.monthly'),
					align: 'center',
					customRender: (obj) => {
						return (
							<span>
								<p>{obj.interestPaid.toFixed(2)}</p>
							</span>
						)
					},
					width: 100,
				},
				{
					title: this.$t('table.finance.cloumRepayment.sum'),
					align: 'center',
					customRender: (obj) => {
						return (
							<span>
								<p>{obj.amount.toFixed(2)}</p>
							</span>
						)
					}
				}
			]
		},
		insuRecordColumns2() {
			return [
				{
					title: this.$t('table.finance.cloumRepayment.trem'),
					align: 'center',
					dataIndex: 'installments2',
					width: 60,
				},
				{
					title: 'Scheduled Repayment Date',
					dataIndex: 'transactionTime',
					align: 'center',
					width: 100,
				},
				{
					title: 'Reference ID',
					scopedSlots: { customRender: 'paymentId' },
					align: 'center',
					width: 100,
				},
				{
					title: ' ',
					scopedSlots: { customRender: 'title2' },
					align: 'center',
					width: 180,
				},
				{
					title: this.$t('table.finance.cloumRepayment.amount'),
					align: 'center',
					customRender: (obj) => {
						return (
							<span>
								<p>{obj.principalPaid.toFixed(2)}</p>
							</span>
						)
					},
					width: 100,
				},
				{
					title: this.$t('table.finance.cloumRepayment.penalty'),
					align: 'center',
					customRender: (obj) => {
						return (
							<span>
								<p>{obj.penaltyPaid.toFixed(2)}</p>
							</span>
						)
					},
					width: 100,
				},
				{
					title: this.$t('table.finance.cloumRepayment.monthly'),
					align: 'center',
					customRender: (obj) => {
						return (
							<span>
								<p>{obj.interestPaid.toFixed(2)}</p>
							</span>
						)
					},
					width: 100,
				},
				{
					title: 'Early Settlement Interest',
					align: 'center',
					customRender: (obj) => {
						return (
							<span>
								<p>{obj.earlyInterestPaid.toFixed(2)}</p>
							</span>
						)
					},
					width: 100,
				},
				{
					title: this.$t('table.finance.cloumRepayment.sum'),
					align: 'center',
					customRender: (obj) => {
						return (
							<span>
								<p>{obj.amount.toFixed(2)}</p>
							</span>
						)
					}
				}
			]
		},
		detailsColumns() {
			return [
				{
					title: this.$t('table.finance.cloumRepayment.referenceId'),
					align: 'center',
					dataIndex: 'paymentId'
				},
				{
					title: this.$t('table.finance.cloumRepayment.time'),
					align: 'center',
					dataIndex: 'transactionDate'
				},
				{
					title: this.$t('table.finance.cloumRepayment.sum'),
					dataIndex: 'amount',
					align: 'center'
				},
				{
					title: this.$t('table.finance.lenging.cloums.receipt'),
					align: 'center',
					scopedSlots: { customRender: 'receipt' }
				},
				{
					title: this.$t(`table.applyInfo.financialLabel.totalRefundAmount`),
					align: 'center',
					customRender: (text, row, index) => {
						if (index === 0) {
							return {
								children: <span>{this.overpaidDetail.totalRefundAmount}</span>,
								attrs: { rowSpan: this.overpaidDetail.repays.length }
							}
						} else {
							return {
								attrs: { rowSpan: 0 }
							}
						}
					}
				},
				{
					title: this.$t(`table.applyInfo.financialLabel.remainingOverpaymentAmount`),
					align: 'center',
					customRender: (text, row, index) => {
						if (index === 0) {
							return {
								children: <span>{this.overpaidDetail.amount}</span>,
								attrs: { rowSpan: this.overpaidDetail.repays.length }
							}
						} else {
							return {
								attrs: { rowSpan: 0 }
							}
						}
					}
				}
			]
		},
		prePaymentColumns() {
			return [
				{
					title: this.$t('table.finance.cloumRepayment.referenceId'),
					align: 'center',
					dataIndex: 'paymentId'
				},
				{
					title: this.$t('table.finance.cloumRepayment.time'),
					align: 'center',
					dataIndex: 'transactionDate'
				},
				{
					title: this.$t('table.finance.cloumRepayment.sum'),
					dataIndex: 'amount',
					align: 'center'
				},
				{
					title: this.$t('table.finance.lenging.cloums.receipt'),
					align: 'center',
					scopedSlots: { customRender: 'receipt' }
				},
				{
					title: 'Total Repayment Amount',
					customRender: (obj) => {
						return (
							<span>{ obj.paymentAmount? obj.paymentAmount.toFixed(2) : 0}</span>
						)
					},
					align: 'center'
				},
				{
					title: 'Remaining Pre-Payment Amount',
					customRender: (obj) => {
						return (
							<span>{ obj.remainsAmount? obj.remainsAmount.toFixed(2) : 0}</span>
						)
					},
					align: 'center'
				},
			]
		},
		chargeRecordCloumns() {
			return [
				{
					// 提交时间 arrivalTime
					title: 'Post Time',
					align: 'center',
					dataIndex: 'createTime'
				},
				{
					// 名称
					title: 'Fee',
					align: 'center',
					scopedSlots: { customRender: 'fee' }
				},
				{
					// Period
					title: 'Period',
					align: 'center',
					scopedSlots: { customRender: 'installments' }
				},
				{
					// Amount
					title: 'Amount',
					align: 'center',
					dataIndex: 'amount'
				},
				{
					// 凭证
					title: this.$t('table.finance.lenging.cloums.receipt'),
					align: 'center',
					scopedSlots: { customRender: 'receipt' }
				},
				{
					// 操作员
					title: this.$t('table.finance.lenging.cloums.actioner'),
					align: 'center',
					dataIndex: 'createUserName'
				},
				{
					// 操作
					title: this.$t('table.userFrom.columns.action'),
					scopedSlots: { customRender: 'action' },
					align: 'center'
				}

			]
		},
		chargeRecordCloumns2() {
			return [
				{
					// 提交时间 arrivalTime
					title: 'Post Time',
					align: 'center',
					dataIndex: 'createTime'
				},
				{
					// 名称
					title: 'Fee',
					align: 'center',
					scopedSlots: { customRender: 'fee' }
				},
				{
					// Period
					title: 'Period',
					align: 'center',
					scopedSlots: { customRender: 'installments' }
				},
				{
					// Amount
					title: 'Amount',
					align: 'center',
					dataIndex: 'amount'
				},
				{
					// 凭证
					title: this.$t('table.finance.lenging.cloums.receipt'),
					align: 'center',
					scopedSlots: { customRender: 'receipt' }
				},
				{
					// 操作员
					title: this.$t('table.finance.lenging.cloums.actioner'),
					align: 'center',
					dataIndex: 'createUserName'
				},
			]
		},
		optionsStatement() {
			// if (this.lengingInfo.loanStatus < 1005) {
			return [
				{ label: 'Loan Statement for Finance', value: 'finance' },
				{ label: 'Loan Statement for Collections', value: 'collections' }
			]
			// } else {
			// 	return [{ label: 'Loan Statement for Finance', value: 'finance' }]
			// }
		},
		emergencyInfo() {
			if(this.emergencyPlanDataSource.length > 0) {
				return this.emergencyPlanDataSource.find(item => item[0].emergencyId == this.additionalLoanId) || []
			} else {
				return []
			}
		},
		emergencyFeeEventsList(){
			if(this.emergencyFeeEvents && this.emergencyFeeEvents.length> 0){
				let arr = [];
				this.emergencyFeeEvents.map(item => {
					if(item.emergencyId == this.additionalLoanId){
						arr.push(item)
					}
				})
				return arr
			} else {
				return []
			}
		},
		insuranceFeeEventsList(){
			if(this.insuranceFeeEvents && this.insuranceFeeEvents.length> 0){
				let arr = [];
				this.insuranceFeeEvents.map(item => {
					if(item.insuranceId == this.additionalLoanId){
						arr.push(item)
					}
				})
				return arr
			} else {
				return []
			}
		},
		emergencyColumns() {
			return [
				{
					// ID
					title: this.$t('table.finance.cloumRepayment.referenceId'),
					align: 'center',
					dataIndex: 'paymentId',
					width: 150,
					fixed: 'left'
				},
				{
					// 总额
					title: 'Total Repayment',
					dataIndex: 'total',
					align: 'center'
				},
				{
					// 期数
					title: this.$t('table.finance.cloumRepayment.trem'),
					align: 'center',
					dataIndex: 'installments'
				},
				{
					// 时间
					title: this.$t('table.finance.cloumRepayment.time'),
					align: 'center',
					dataIndex: 'transactionTime'
				},
				{
					// 总额
					title: this.$t('table.finance.cloumRepayment.sum'),
					dataIndex: 'amount',
					align: 'center'
				},
				{
					// 本金
					title: this.$t('table.finance.cloumRepayment.amount'),
					dataIndex: 'principalPaid',
					align: 'center'
				},
				{
					// 罚息
					title: this.$t('table.finance.cloumRepayment.penalty'),
					dataIndex: 'penaltyPaid',
					align: 'center'
				},
				{
					// 月度罚息
					title: this.$t('table.finance.cloumRepayment.monthly'),
					dataIndex: 'interestPaid',
					align: 'center'
				},
				{ // Early Settlement Interest
					title: 'Early Settlement Interest',
					dataIndex: 'earlyInterestPaid',
					align: 'center',
					customRender: (v, o) => { return o.earlyInterestExtraPaid + '/' + v } 
				}
			]
		},
		insuranceColumns() {
			return [
				{
					// ID
					title: this.$t('table.finance.cloumRepayment.referenceId'),
					align: 'center',
					dataIndex: 'paymentId',
					width: 150,
					fixed: 'left'
				},
				{
					// 总额
					title: 'Total Repayment',
					dataIndex: 'total',
					align: 'center'
				},
				{
					// 期数
					title: this.$t('table.finance.cloumRepayment.trem'),
					align: 'center',
					dataIndex: 'installments'
				},
				{
					// 时间
					title: this.$t('table.finance.cloumRepayment.time'),
					align: 'center',
					dataIndex: 'transactionTime'
				},
				{
					// 总额
					title: this.$t('table.finance.cloumRepayment.sum'),
					dataIndex: 'amount',
					align: 'center'
				},
				{
					// 本金
					title: this.$t('table.finance.cloumRepayment.amount'),
					dataIndex: 'principalPaid',
					align: 'center'
				},
				{
					// 罚息
					title: this.$t('table.finance.cloumRepayment.penalty'),
					dataIndex: 'penaltyPaid',
					align: 'center'
				},
				{
					// 月度罚息
					title: this.$t('table.finance.cloumRepayment.monthly'),
					dataIndex: 'interestPaid',
					align: 'center'
				}
			]
		},
		repaymentColumns() {
			return [
				{
					// Reference ID
					title: this.$t('table.finance.cloumRepayment.referenceId'),
					align: 'center',
					dataIndex: 'paymentId',
					width: 150,
					fixed: 'left'
				},
				{
					// 总额
					title: 'Total Repayment',
					dataIndex: 'total',
					align: 'center'
				},
				{
					// 期数
					title: this.$t('table.finance.cloumRepayment.trem'),
					align: 'center',
					dataIndex: 'installments'
				},
				{
					// 时间
					title: this.$t('table.finance.cloumRepayment.time'),
					align: 'center',
					dataIndex: 'transactionTime'
				},
				{
					// 总额
					title: this.$t('table.finance.cloumRepayment.sum'),
					dataIndex: 'amount',
					align: 'center'
				},
				{
					// 本金
					title: this.$t('table.finance.cloumRepayment.amount'),
					dataIndex: 'principalPaid',
					align: 'center'
				},
				{
					// GPS费用
					title: this.$t('table.finance.cloumRepayment.gpsFee'),
					dataIndex: 'trackingFeePaid',
					align: 'center',
					customRender: (v, o) => {
						return o.trackingFeeExtraPaid + '/' + v
					}
				},
				{
					title: this.$t('table.applyInfo.loanApplyLabel.maintenanceFee'),
					dataIndex: 'maintenanceFeePaid',
					align: 'center',
					customRender: (v, o) => {
						return o.maintenanceFeeExtraPaid + '/' + v
					}
				},
				{
					// 其他
					title: this.$t('table.finance.cloumRepayment.other'),
					align: 'center',
					scopedSlots: { customRender: 'otherFeePaid' }
				},
				{
					// 罚息
					title: this.$t('table.finance.cloumRepayment.penalty'),
					dataIndex: 'penaltyPaid',
					align: 'center',
					customRender: (v, o) => {
						return o.penaltyExtraPaid + '/' + v
					}
				},
				{
					// 月度罚息
					title: this.$t('table.finance.cloumRepayment.monthly'),
					dataIndex: 'interestPaid',
					align: 'center',
					customRender: (v, o) => {
						return o.interestExtraPaid + '/' + v
					}
				},
				{
					// Early Settlement Interest
					title: 'Early Settlement Interest',
					dataIndex: 'earlyInterestPaid',
					align: 'center',
					customRender: (v, o) => {
						return o.earlyInterestExtraPaid + '/' + v
					}
				}
			]
		}
	},
	methods: {
		moment: moment,
		uploadRepayment() {
			this.$refs.uploadRepayment.uploadRepayment(this.lengingInfo.loanId, null, 'repaymentEntryEarly2')
		},
		repaymentsInitTable(){
			this.$message.success('Success')
			location.reload()
		},
		SeePenaltyModal(obj) {
			this.penaltyModalShow = true
			this.penaltyType = obj.type.value
			this.penaltyId = obj.id
		},
		deletePenalty(obj) {
			this.$confirm({
				content: 'Are you sure to delete the charge?',
				onOk: () => {
					apiDeleteFee({
						id: obj.id, //罚息的记录id
						loanId: this.$route.query.id, //案件id
						feeType: obj.type.value
					}).then((res) => {
						this.$message.success('success')
						location.reload()
					})
				},
				onCancel() {}
			})
		},
		handModalOk() {
			if (this.penaltyType === 0 || this.penaltyType === 1) {
				if (!this.penaltyTime) {
					this.$message.error('Penalty Time is empty!')
					return false
				}
			} else {
				if (!this.checkInstallments) {
					this.$message.error('Period is empty!')
					return false
				}
			}
			this.$confirm({
				content: this.$t('table.applyInfo.msgMap.msg1'),
				onOk: () => {
					if (this.penaltyType === 0 || this.penaltyType === 1) {
						apiRegeneratePenalty({
							id: this.penaltyId, //罚息的记录id
							loanId: this.$route.query.id, //案件id
							penaltyTime: moment(this.penaltyTime).format('YYYY-MM-DD HH:mm:ss')
						}).then((res) => {
							this.$message.success('success')
							this.penaltyModalShow = false
							location.reload()
						})
					} else {
						apiModifyFee({
							id: this.penaltyId, //罚息的记录id
							loanId: this.$route.query.id, //案件id
							feeType: this.penaltyType,
							installments: this.checkInstallments
						}).then((res) => {
							this.$message.success('success')
							this.penaltyModalShow = false
							location.reload()
						})
					}
				},
				onCancel() {}
			})
		},
		disabledDate(current) {
			return current && current < this.moment().endOf('day')
		},
		// 其他弹窗
		ShowModal(obj, type) {
			if(obj.installments == 'Total'){
				return false
			}
			let params = { loanId: this.id }
			if (obj.installments == 'Total') {
			} else {
				params.installments = obj.installments
			}
			const otherFee = [
				{
					feeName: this.$t('table.finance.cloumRepayment.checkFee'),
					amount: obj[`checkReturnFee${type}`]
				},
				{
					feeName: this.$t('table.finance.cloumRepayment.cost'),
					amount: obj[`repossessionFee${type}`]
				},
				{
					feeName: this.$t('table.finance.cloumRepayment.costCode'),
					amount: obj[`repossessionOrderFee${type}`]
				},
				{
					feeName: this.$t('table.finance.cloumRepayment.settle'),
					amount: obj[`logbookFee${type}`]
				}
			]
			this.lookFeeModal.dataSource = obj.otherFee ? [...obj.otherFee.details, ...otherFee] : [...otherFee]
			this.lookFeeModal.installments = obj.installments
			this.lookFeeModal.statusId = obj.statusId
			this.lookFeeModal.show = true
		},
		onExport() {
			if (this.checkedStatement.length === 0) {
				this.$message.error('Select Empty')
				return false
			}
			this.checkedStatement.includes('finance') ? this.$refs.financeLoanStatement.onExport() : ''
			this.checkedStatement.includes('collections') ? this.$refs.collectionsLoanStatement.onExport() : ''
			this.loanStatementVisible = false
			this.checkedStatement = []
		},
		// 客户录入费用类型改变
		ChangeType() {
			this.FeeModal.certificate = ''
			this.FeeModal.amount = ''
			this.FeeModal.transactionTime = ''
			this.FeeModal.bankName = ''
			this.FeeModal.checkInstallments = ''
			this.FeeModal.dataSource = []
		},
		// 删除
		Del(idx) {
			this.FeeModal.dataSource.splice(idx, 1)
		},
		AddData() {
			this.FeeModal.dataSource.push({
				feeName: '',
				feeAmount: '',
				installments: '',
				voucher: ''
			})
		},
		// 录入客户费用
		LuFee() {
			if (this.lengingInfo.loanStatus > 1004) {
				this.$message.error('Loan Cleared!')
				return false
			}
			this.FeeModal.id = this.lengingInfo.loanId
			this.FeeModal.type = 4
			this.FeeModal.dataSource = []
			this.FeeModal.amount = ''
			this.FeeModal.bankName = ''
			this.FeeModal.checkInstallments = ''
			this.FeeModal.installmentsNumbers = this.lengingInfo.installmentsNumbers
			this.FeeModal.show = true
		},
		// 表单提交
		SaveFrom() {
			let params = {
				loanId: this.FeeModal.id,
				installments: this.FeeModal.checkInstallments
			}
			if (this.FeeModal.type == 1) {
				params.feeType = 'paid_fail'
				params.amount = this.FeeModal.amount
				params.bankName = this.FeeModal.bankName
				params.certificate = this.FeeModal.certificate
				if (!params.amount) {
					this.$message.error('amount is empty!')
					return false
				}else if (!params.bankName) {
					this.$message.error('bankname is empty!')
					return false
				} else if (!params.installments) {
					this.$message.error('Period is empty!')
					return false
				}
				// else if (!params.certificate) {
				// 	this.$message.error('file is empty!')
				// 	return false
				// }
			} else if (this.FeeModal.type == 2 || this.FeeModal.type == 3) {
				params.feeType = this.FeeModal.type == 2 ? 'collect_car' : 'transfer'
				params.amount = this.FeeModal.amount
				params.certificate = this.FeeModal.certificate
				if (!params.amount) {
					this.$message.error('amount is empty!')
					return false
				} else if (!params.installments) {
					this.$message.error('Period is empty!')
					return false
				}
				// else if (!params.certificate) {
				// 	this.$message.error('file is empty!')
				// 	return false
				// }
			} else if (this.FeeModal.type == 4) {
				params.feeType = 'others'
				params.details = this.FeeModal.dataSource
				delete params.installments
				if (params.details.length == 0) {
					this.$message.error('details is empty!')
					return false
				}
			}
			this.$confirm({
				title: '',
				content: this.$t('table.applyInfo.msgMap.msg1'),
				onOk: () => {
					apiFeeInput(params).then((res) => {
						this.$message.success('success')
						this.FeeModal.show = false
						location.reload()
					})
				},
				onCancel() {}
			})
		},
		showDueDateModal() {
			this.dueDateModal = {
				show: true,
				loanId: this.$route.query.id,
				installments: this.dueDateInstList[0],
				changedDueDate: '',
				receipt: ''
			}
		},
		changeDueDay() {
			if (!this.dueDateModal.receipt) {
				this.$message.error('Upload Form is empty!')
				return false
			}
			this.$confirm({
				content: "Do you sure to change the due date? You can't undo this operation.",
				onOk: () => {
					this.dueDateModal.changedDueDate = this.moment(this.dueDateModal.changedDueDate).format('YYYY-MM-DD HH:mm:ss')
					apiChangeDueDay({ ...this.dueDateModal }).then((res) => {
						this.$message.success('success')
						this.dueDateModal.show = false
						location.reload()
					})
				},
				onCancel() {}
			})
		},
		changeInterest() {
			if (!this.interestModal.interest) {
				this.$message.error('Interest is empty!')
				return false
			}
			this.$confirm({
				content: 'Do you sure to change interest?',
				onOk: () => {
					apiChangeInterest({ ...this.interestModal }).then((res) => {
						this.$message.success('success')
						this.interestModal.show = false
						location.reload()
					})
				},
				onCancel() {}
			})
		},
		exportWordDocx() {
			apiRepaymentSchedule({
				loanId: this.$route.query.id
			}).then((res) => {
				const basePrefix = process.env.NODE_ENV === 'development' ? `/bccms/` : `/`
				let IPFItem = []
				if (res.insurances.length > 0) {
					res.insurances.forEach((element) => {
						IPFItem.push({
							Item: `IPF Interest: ${(element.interest * 100).toFixed(1)}%`
						})
						IPFItem.push({
							Item: `IPF Principal: Ksh ${element.principalAmount.toFixed(2)}`
						})
					})
				}
				if (res.emergencies && res.emergencies.length > 0) {
					res.emergencies.forEach((element) => {
						IPFItem.push({
							Item: `${this.$t(`table.applyInfo.financialLabel.loanMap.${element.loanType}`)} Loan Interest: ${(element.interest * 100).toFixed(1)}%`
						})
						IPFItem.push({
							Item: `${this.$t(`table.applyInfo.financialLabel.loanMap.${element.loanType}`)} Loan Prinsipal: Ksh ${element.principalAmount.toFixed(
								2
							)}`
						})
					})
				}
				exportWordDocx(
					`${basePrefix}repaymentSchedule.docx`,
					{
						Name: res.clientName,
						ID: res.nationalId,
						PhoneNumber: res.phoneNums.join('/'),
						Vehicle: res.vehicles.join('/'),
						PrincipalAmount: res.principalAmount.toFixed(2),
						Interest: (res.interest * 100).toFixed(1),
						InstallmentAmountsTable: res.installmentAmounts,
						IPFItem: IPFItem,
						Branch: res.clientName
					},
					`${res.clientName} Repayment Schedule.docx`
				)
			})
		},
		ShowPaymentDatail(paymentId) {
			let obj = this.repays.find(item => item.paymentId == paymentId)
			this.repaymentDatils.paymentId = paymentId;
			this.repaymentDatils.transactionType = obj.transactionTypeEnum.value;
			this.repaymentDatils.paymentAmount = obj.amount;
			this.repaymentDatils.mobileNo = obj.mobileNo;
			this.repaymentDatils.transactionDate = obj.transactionDate;
			this.repaymentDatils.visible = true;
		},
		getOtherFeeDetails(val) {
			val
				? val.map((item) => {
						item.otherFeeDetails =
							item.checkReturnFeePaid + item.repossessionFeePaid + item.repossessionOrderFeePaid + item.logbookFeePaid + item.otherFeePaid
				  })
				: []
			return val
		},
		showOtherCharges(data) {
			this.otherChargeModal.certificates = data.certificates;
			this.otherChargeModal.details = data.details;
			this.otherChargeModal.visible = true;
		},
		GetInstallments(data) {
			let arr = [];
			if(data.details){
				data.details.map(item => {
					arr.push(item.installments)
				})
			}
			return arr.join(',') || data.installments.toString()
		},
		CanAction(data) {
			if(this.NotRepaidInstallmentsOptions.length == 0) {
				return false
			} else {
				let arr = this.GetInstallments(data).split(','), idx = 0, _this = this;
				arr.map(item => {
					if(_this.NotRepaidInstallmentsOptions.includes(parseInt(item))){
						idx++
					}
				})
				if(idx > 0) {
					return true
				} else {
					return false
				}
			}
		}
	},
	created() {
		this.$nextTick(() => {
			let obj = {
				installments: 'Total',
				principalDue: 0,
				principalPaid: 0,
				principalOutstanding: 0,
				principalDafult: 0,

				maintenanceFeeDue: 0,
				maintenanceFeeExtraPaid: 0,
				maintenanceFeeOutstanding: 0,
				maintenanceFeePaid: 0,
				maintenanceFeeDafult: 0,

				trackingFeeDue: 0,
				trackingFeePaid: 0,
				trackingFeeExtraPaid: 0,
				trackingFeeOutstanding: 0,
				trackingFeeDafult: 0,

				checkReturnFeeDue: 0,
				checkReturnFeePaid: 0,
				checkReturnFeeOutstanding: 0,
				checkReturnFeeDafult: 0,

				repossessionFeeDue: 0,
				repossessionFeePaid: 0,
				repossessionFeeOutstanding: 0,
				repossessionFeeDafult: 0,

				repossessionOrderFeeDue: 0,
				repossessionOrderFeePaid: 0,
				repossessionOrderFeeOutstanding: 0,
				repossessionOrderFeeDafult: 0,

				otherFeeDueTal: 0,
				otherFeePaidTal: 0,
				otherFeeOutstandingTal: 0,
				otherFeeDafultTal: 0,

				otherFeeDue: 0,
				otherFeePaid: 0,
				otherFeeExtraPaid: 0,
				otherFeeOutstanding: 0,
				otherFeeDafult: 0,

				penaltyDue: 0,
				penaltyPaid: 0,
				penaltyExtraPaid: 0,
				penaltyOutstanding: 0,
				penaltyDafult: 0,

				interestDue: 0,
				interestPaid: 0,
				interestExtraPaid: 0,
				interestOutstanding: 0,
				interestDafult: 0,

				logbookFeeDue: 0,
				logbookFeePaid: 0,
				logbookFeeOutstanding: 0,
				logbookFeeDafult: 0,

				earlyInterestDue: 0,
				earlyInterestExtraPaid: 0,
				earlyInterestOutstanding: 0,
				earlyInterestPaid: 0,
				earlyInterestDafult: 0,

				totalDue: 0,
				totalPaid: 0,
				totalExtraPaid: 0,
				totalOutstanding: 0,
				totalDafult: 0,
				details: []
			}
			this.repayPlan.map((item) => {
				item.principalDafult = item.overdue?item.principalOutstanding: 0
				obj.principalDue += item.principalDue
				obj.principalPaid += item.principalPaid
				obj.principalOutstanding += item.principalOutstanding;
				obj.principalDafult += item.principalDafult;

				item.maintenanceFeeDafult = item.overdue?item.maintenanceFeeOutstanding: 0
				obj.maintenanceFeeDue += item.maintenanceFeeDue
				obj.maintenanceFeeExtraPaid += item.maintenanceFeeExtraPaid
				obj.maintenanceFeeOutstanding += item.maintenanceFeeOutstanding
				obj.maintenanceFeePaid += item.maintenanceFeePaid
				obj.maintenanceFeeDafult += item.maintenanceFeeDafult

				item.trackingFeeDafult = item.overdue?item.trackingFeeOutstanding: 0
				obj.trackingFeeDue += item.trackingFeeDue
				obj.trackingFeePaid += item.trackingFeePaid
				obj.trackingFeeExtraPaid += item.trackingFeeExtraPaid
				obj.trackingFeeOutstanding += item.trackingFeeOutstanding
				obj.trackingFeeDafult += item.trackingFeeDafult

				item.checkReturnFeeDafult = item.overdue?item.checkReturnFeeOutstanding: 0
				obj.checkReturnFeeDue += item.checkReturnFeeDue
				obj.checkReturnFeePaid += item.checkReturnFeePaid
				obj.checkReturnFeeOutstanding += item.checkReturnFeeOutstanding
				obj.checkReturnFeeDafult += item.checkReturnFeeDafult

				item.repossessionFeeDafult = item.overdue?item.repossessionFeeOutstanding: 0
				obj.repossessionFeeDue += item.repossessionFeeDue
				obj.repossessionFeePaid += item.repossessionFeePaid
				obj.repossessionFeeOutstanding += item.repossessionFeeOutstanding
				obj.repossessionFeeDafult += item.repossessionFeeDafult

				item.repossessionOrderFeeDafult = item.overdue?item.repossessionOrderFeeOutstanding: 0
				obj.repossessionOrderFeeDue += item.repossessionOrderFeeDue
				obj.repossessionOrderFeePaid += item.repossessionOrderFeePaid
				obj.repossessionOrderFeeOutstanding += item.repossessionOrderFeeOutstanding
				obj.repossessionOrderFeeDafult += item.repossessionOrderFeeDafult

				item.otherFeeDafult = item.overdue?item.otherFeeOutstanding: 0
				obj.otherFeeDue += item.otherFeeDue
				obj.otherFeePaid += item.otherFeePaid
				obj.otherFeeExtraPaid += item.otherFeeExtraPaid
				obj.otherFeeOutstanding += item.otherFeeOutstanding
				obj.otherFeeDafult += item.otherFeeDafult

				item.otherFeeDueTal = 0
				item.otherFeePaidTal = 0
				item.otherFeeOutstandingTal = 0
				// 其他费用集合
				item.otherFeeDueTal += item.otherFeeDue + item.checkReturnFeeDue + item.repossessionFeeDue + item.repossessionOrderFeeDue + item.logbookFeeDue
				item.otherFeePaidTal +=
					item.otherFeePaid + item.checkReturnFeePaid + item.repossessionFeePaid + item.repossessionOrderFeePaid + item.logbookFeePaid
				item.otherFeeOutstandingTal +=
					item.otherFeeOutstanding +
					item.checkReturnFeeOutstanding +
					item.repossessionFeeOutstanding +
					item.repossessionOrderFeeOutstanding +
					item.logbookFeeOutstanding;
				item.otherFeeDafultTal = item.overdue?item.otherFeeOutstandingTal: 0

				obj.otherFeeDueTal += item.otherFeeDueTal
				obj.otherFeePaidTal += item.otherFeePaidTal
				obj.otherFeeOutstandingTal += item.otherFeeOutstandingTal
				obj.otherFeeDafultTal += item.otherFeeDafultTal

				item.penaltyDafult = item.overdue?item.penaltyOutstanding: 0
				obj.penaltyDue += item.penaltyDue
				obj.penaltyPaid += item.penaltyPaid
				obj.penaltyExtraPaid += item.penaltyExtraPaid
				obj.penaltyOutstanding += item.penaltyOutstanding
				obj.penaltyDafult += item.penaltyDafult

				item.interestDafult = item.overdue?item.interestOutstanding: 0
				obj.interestDue += item.interestDue
				obj.interestPaid += item.interestPaid
				obj.interestExtraPaid += item.interestExtraPaid
				obj.interestOutstanding += item.interestOutstanding
				obj.interestDafult += item.interestDafult

				item.logbookFeeDafult = item.overdue?item.logbookFeeOutstanding: 0
				obj.logbookFeeDue += item.logbookFeeDue
				obj.logbookFeePaid += item.logbookFeePaid
				obj.logbookFeeOutstanding += item.logbookFeeOutstanding
				obj.logbookFeeDafult += item.logbookFeeDafult

				item.earlyInterestDafult = item.overdue?item.earlyInterestOutstanding: 0
				obj.earlyInterestDue += item.earlyInterestDue
				obj.earlyInterestExtraPaid += item.earlyInterestExtraPaid
				obj.earlyInterestOutstanding += item.earlyInterestOutstanding
				obj.earlyInterestDafult += item.earlyInterestDafult

				item.totalDafult = item.overdue?item.totalOutstanding: 0
				obj.totalDue += item.totalDue
				obj.totalPaid += item.totalPaid
				obj.totalExtraPaid += item.totalExtraPaid
				obj.totalOutstanding += item.totalOutstanding
				obj.totalDafult += item.totalDafult;
				item.details = item.details?item.details: []
				item.details.map((item2, index)=> {
					item2.installments = item2.installments + ' - ' + (index+1)
					item2.otherFeePaidTal = item2.otherFeePaid + item2.checkReturnFeePaid + item2.repossessionFeePaid + item2.repossessionOrderFeePaid + item2.logbookFeePaid;
				})
			})
			if (this.repayPlan.length > 0) {
				obj.duedate = this.repayPlan.at(-1).duedate
				this.repayPlanDataSource = this.repayPlan.concat([obj])
			}
			const insuranceTotalList = []
			this.insurancePlans.forEach((element) => {
				element.map(item=> {
					item.details = item.details?item.details: [];
					if(item.overdue) {
						item.principalDefault = item.principalOutstanding
						item.interestDefault = item.interestOutstanding
						item.penaltyDefault = item.penaltyOutstanding
						item.totalDefault = item.totalOutstanding
					} else {
						item.principalDefault = 0
						item.interestDefault = 0
						item.penaltyDefault = 0
						item.totalDefault = 0
					}
					item.details.map((item2, index)=> {
						item2.installments2 = item2.installments + ' - ' + (index+1)
					})
				})
				const insuranceTotal = {
					installments: 'Total',
					principalDue: element.reduce((pre, cur) => {
						return pre + cur.principalDue
					}, 0),
					principalPaid: element.reduce((pre, cur) => {
						return pre + cur.principalPaid
					}, 0),
					principalOutstanding: element.reduce((pre, cur) => {
						return pre + cur.principalOutstanding
					}, 0),
					principalDefault: element.reduce((pre, cur) => {
						return pre + cur.principalDefault
					}, 0),
					interestDue: element.reduce((pre, cur) => {
						return pre + cur.interestDue
					}, 0),
					interestPaid: element.reduce((pre, cur) => {
						return pre + cur.interestPaid
					}, 0),
					interestOutstanding: element.reduce((pre, cur) => {
						return pre + cur.interestOutstanding
					}, 0),
					interestDefault: element.reduce((pre, cur) => {
						return pre + cur.interestDefault
					}, 0),
					penaltyDue: element.reduce((pre, cur) => {
						return pre + cur.penaltyDue
					}, 0),
					penaltyPaid: element.reduce((pre, cur) => {
						return pre + cur.penaltyPaid
					}, 0),
					penaltyOutstanding: element.reduce((pre, cur) => {
						return pre + cur.penaltyOutstanding
					}, 0),
					penaltyDefault: element.reduce((pre, cur) => {
						return pre + cur.penaltyDefault
					}, 0),
					totalDue: element.reduce((pre, cur) => {
						return pre + cur.totalDue
					}, 0),
					totalPaid: element.reduce((pre, cur) => {
						return pre + cur.totalPaid
					}, 0),
					totalOutstanding: element.reduce((pre, cur) => {
						return pre + cur.totalOutstanding
					}, 0),
					totalDefault: element.reduce((pre, cur) => {
						return pre + cur.totalDefault
					}, 0)
				}
				insuranceTotal.duedate = element.at(-1).duedate
				insuranceTotalList.push(insuranceTotal)
				this.insurancePlanDataSource.push([...element, insuranceTotal])
			})
			this.insurInfo = []
			this.insurancePlanDataSource.map(item => {
				if(this.additionalLoanId == item[0].insuranceId){
					this.insurInfo = item;
				}
			})
			this.emergencyPlans.forEach((element) => {
				element.map(item=> {
					item.details = item.details?item.details: []
					if(item.overdue) {
						item.principalDefault = item.principalOutstanding
						item.interestDefault = item.interestOutstanding
						item.penaltyDefault = item.penaltyOutstanding
						item.totalDefault = item.totalOutstanding
						item.earlyInterestDafult = item.earlyInterestOutstanding
					} else {
						item.principalDefault = 0
						item.interestDefault = 0
						item.penaltyDefault = 0
						item.totalDefault = 0
						item.earlyInterestDafult = 0
					}
					item.details.map((item2, index)=> {
						item2.installments2 = item2.installments + ' - ' + (index+1)
					})
				})
				const insuranceTotal = {
					installments: 'Total',
					principalDue: element.reduce((pre, cur) => {
						return pre + cur.principalDue
					}, 0),
					principalPaid: element.reduce((pre, cur) => {
						return pre + cur.principalPaid
					}, 0),
					principalOutstanding: element.reduce((pre, cur) => {
						return pre + cur.principalOutstanding
					}, 0),
					principalDefault: element.reduce((pre, cur) => {
						return pre + cur.principalDefault
					}, 0),
					interestDue: element.reduce((pre, cur) => {
						return pre + cur.interestDue
					}, 0),
					interestPaid: element.reduce((pre, cur) => {
						return pre + cur.interestPaid
					}, 0),
					interestOutstanding: element.reduce((pre, cur) => {
						return pre + cur.interestOutstanding
					}, 0),
					interestDefault: element.reduce((pre, cur) => {
						return pre + cur.interestDefault
					}, 0),
					penaltyDue: element.reduce((pre, cur) => {
						return pre + cur.penaltyDue
					}, 0),
					penaltyPaid: element.reduce((pre, cur) => {
						return pre + cur.penaltyPaid
					}, 0),
					penaltyOutstanding: element.reduce((pre, cur) => {
						return pre + cur.penaltyOutstanding
					}, 0),
					penaltyDefault: element.reduce((pre, cur) => {
						return pre + cur.penaltyDefault
					}, 0),

					earlyInterestDue: element.reduce((pre, cur) => {
						return pre + cur.earlyInterestDue
					}, 0),
					earlyInterestPaid: element.reduce((pre, cur) => {
						return pre + cur.earlyInterestPaid
					}, 0),
					earlyInterestOutstanding: element.reduce((pre, cur) => {
						return pre + cur.earlyInterestOutstanding
					}, 0),
					earlyInterestDafult: element.reduce((pre, cur) => {
						return pre + cur.earlyInterestDafult
					}, 0),

					totalDue: element.reduce((pre, cur) => {
						return pre + cur.totalDue
					}, 0),
					totalPaid: element.reduce((pre, cur) => {
						return pre + cur.totalPaid
					}, 0),
					totalOutstanding: element.reduce((pre, cur) => {
						return pre + cur.totalOutstanding
					}, 0),
					totalDefault: element.reduce((pre, cur) => {
						return pre + cur.totalDefault
					}, 0)
				}
				insuranceTotal.duedate = element.at(-1).duedate
				insuranceTotalList.push(insuranceTotal)
				this.emergencyPlanDataSource.push([...element, insuranceTotal])
			})
			const canvasDataMark = []
			this.repayPlan.forEach((item) => {
				canvasDataMark.push(item.installments)
				this.canvasDataSource.push({
					name: 'M' + item.installments + ' Receivable',
					principal: item.principalDue,
					trackingFee: item.trackingFeeDue,
					maintenanceFee: item.maintenanceFeeDue,
					otherFee: item.otherFeeDue,
					penalty: item.penaltyDue,
					interest: item.interestDue,
					total: item.totalDue
				})
				this.canvasDataSource.push({
					name: 'M' + item.installments + ' Repayment',
					principal: item.principalPaid,
					trackingFee: item.trackingFeePaid,
					maintenanceFee: item.maintenanceFeePaid,
					otherFee: item.otherFeePaid,
					penalty: item.penaltyPaid,
					interest: item.interestPaid,
					total: item.totalPaid
				})
				this.canvasDataSource.push({
					name: 'M' + item.installments + ' Balance',
					principal: item.principalOutstanding,
					trackingFee: item.trackingFeeOutstanding,
					maintenanceFee: item.maintenanceFeeOutstanding,
					otherFee: item.otherFeeOutstanding,
					penalty: item.penaltyOutstanding,
					interest: item.interestOutstanding,
					total: item.totalOutstanding
				})
			})
			this.insurancePlans.forEach((element) => {
				element.forEach((insItem) => {
					if (canvasDataMark.includes(insItem.installments)) {
						this.canvasDataSource[(insItem.installments - 1) * 3].principal += insItem.principalDue
						this.canvasDataSource[(insItem.installments - 1) * 3].penalty += insItem.penaltyDue
						this.canvasDataSource[(insItem.installments - 1) * 3].interest += insItem.interestDue
						this.canvasDataSource[(insItem.installments - 1) * 3].total += insItem.totalDue

						this.canvasDataSource[(insItem.installments - 1) * 3 + 1].principal += insItem.principalPaid
						this.canvasDataSource[(insItem.installments - 1) * 3 + 1].penalty += insItem.penaltyPaid
						this.canvasDataSource[(insItem.installments - 1) * 3 + 1].interest += insItem.interestPaid
						this.canvasDataSource[(insItem.installments - 1) * 3 + 1].total += insItem.totalPaid

						this.canvasDataSource[(insItem.installments - 1) * 3 + 2].principal += insItem.principalOutstanding
						this.canvasDataSource[(insItem.installments - 1) * 3 + 2].penalty += insItem.penaltyOutstanding
						this.canvasDataSource[(insItem.installments - 1) * 3 + 2].interest += insItem.interestOutstanding
						this.canvasDataSource[(insItem.installments - 1) * 3 + 2].total += insItem.totalOutstanding
					} else {
						this.canvasDataSource.push({
							name: 'M' + insItem.installments + ' Receivable',
							principal: insItem.principalDue,
							trackingFee: insItem.trackingFeeDue ? insItem.trackingFeeDue : 0,
							otherFee: insItem.otherFeeDue ? insItem.otherFeeDue : 0,
							maintenanceFee: insItem.maintenanceFeeDue ? insItem.maintenanceFeeDue : 0,
							penalty: insItem.penaltyDue,
							interest: insItem.interestDue,
							total: insItem.totalDue
						})
						this.canvasDataSource.push({
							name: 'M' + insItem.installments + ' Repayment',
							principal: insItem.principalPaid,
							trackingFee: 0,
							otherFee: 0,
							maintenanceFee: insItem.maintenanceFeePaid ? insItem.maintenanceFeePaid : 0,
							penalty: insItem.penaltyPaid,
							interest: insItem.interestPaid,
							total: insItem.totalPaid
						})
						this.canvasDataSource.push({
							name: 'M' + insItem.installments + ' Balance',
							principal: insItem.principalOutstanding,
							trackingFee: 0,
							otherFee: 0,
							maintenanceFee: insItem.maintenanceFeeOutstanding ? insItem.maintenanceFeeOutstanding : 0,
							penalty: insItem.penaltyOutstanding,
							interest: insItem.interestOutstanding,
							total: insItem.totalOutstanding
						})
					}
				})
			})
			this.emergencyPlans.forEach((element) => {
				element.forEach((insItem) => {
					if (canvasDataMark.includes(insItem.installments)) {
						this.canvasDataSource[(insItem.installments - 1) * 3].principal += insItem.principalDue
						this.canvasDataSource[(insItem.installments - 1) * 3].penalty += insItem.penaltyDue
						this.canvasDataSource[(insItem.installments - 1) * 3].interest += insItem.interestDue
						this.canvasDataSource[(insItem.installments - 1) * 3].total += insItem.totalDue

						this.canvasDataSource[(insItem.installments - 1) * 3 + 1].principal += insItem.principalPaid
						this.canvasDataSource[(insItem.installments - 1) * 3 + 1].penalty += insItem.penaltyPaid
						this.canvasDataSource[(insItem.installments - 1) * 3 + 1].interest += insItem.interestPaid
						this.canvasDataSource[(insItem.installments - 1) * 3 + 1].total += insItem.totalPaid

						this.canvasDataSource[(insItem.installments - 1) * 3 + 2].principal += insItem.principalOutstanding
						this.canvasDataSource[(insItem.installments - 1) * 3 + 2].penalty += insItem.penaltyOutstanding
						this.canvasDataSource[(insItem.installments - 1) * 3 + 2].interest += insItem.interestOutstanding
						this.canvasDataSource[(insItem.installments - 1) * 3 + 2].total += insItem.totalOutstanding
					} else {
						this.canvasDataSource.push({
							name: 'M' + insItem.installments + ' Receivable',
							principal: insItem.principalDue,
							trackingFee: insItem.trackingFeeDue ? insItem.trackingFeeDue : 0,
							otherFee: insItem.otherFeeDue ? insItem.otherFeeDue : 0,
							maintenanceFee: insItem.maintenanceFeeDue ? insItem.maintenanceFeeDue : 0,
							penalty: insItem.penaltyDue,
							interest: insItem.interestDue,
							total: insItem.totalDue
						})
						this.canvasDataSource.push({
							name: 'M' + insItem.installments + ' Repayment',
							principal: insItem.principalPaid,
							trackingFee: 0,
							otherFee: 0,
							maintenanceFee: insItem.maintenanceFeePaid ? insItem.maintenanceFeePaid : 0,
							penalty: insItem.penaltyPaid,
							interest: insItem.interestPaid,
							total: insItem.totalPaid
						})
						this.canvasDataSource.push({
							name: 'M' + insItem.installments + ' Balance',
							principal: insItem.principalOutstanding,
							trackingFee: 0,
							otherFee: 0,
							maintenanceFee: insItem.maintenanceFeeOutstanding ? insItem.maintenanceFeeOutstanding : 0,
							penalty: insItem.penaltyOutstanding,
							interest: insItem.interestOutstanding,
							total: insItem.totalOutstanding
						})
					}
				})
			})
			this.canvasDataSource.push({
				name: 'Total Receivable',
				principal:
					obj.principalDue +
					insuranceTotalList.reduce((pre, cur) => {
						return pre + cur.principalDue
					}, 0),
				trackingFee: obj.trackingFeeDue,
				maintenanceFee: obj.maintenanceFeeDue,
				otherFee: obj.otherFeeDue,
				penalty:
					obj.penaltyDue +
					insuranceTotalList.reduce((pre, cur) => {
						return pre + cur.penaltyDue
					}, 0),
				interest:
					obj.interestDue +
					insuranceTotalList.reduce((pre, cur) => {
						return pre + cur.interestDue
					}, 0),
				total:
					obj.totalDue +
					insuranceTotalList.reduce((pre, cur) => {
						return pre + cur.totalDue
					}, 0)
			})
			this.canvasDataSource.push({
				name: 'Total Repayment',
				principal:
					obj.principalPaid +
					insuranceTotalList.reduce((pre, cur) => {
						return pre + cur.principalPaid
					}, 0),
				trackingFee: obj.trackingFeePaid,
				maintenanceFee: obj.maintenanceFeePaid,
				otherFee: obj.otherFeePaid,
				penalty:
					obj.penaltyPaid +
					insuranceTotalList.reduce((pre, cur) => {
						return pre + cur.penaltyPaid
					}, 0),
				interest:
					obj.interestPaid +
					insuranceTotalList.reduce((pre, cur) => {
						return pre + cur.interestPaid
					}, 0),
				total:
					obj.totalPaid +
					insuranceTotalList.reduce((pre, cur) => {
						return pre + cur.totalPaid
					}, 0)
			})
			this.canvasDataSource.push({
				name: 'Total Balance',
				principal:
					obj.principalOutstanding +
					insuranceTotalList.reduce((pre, cur) => {
						return pre + cur.principalOutstanding
					}, 0),
				trackingFee: obj.trackingFeeOutstanding,
				maintenanceFee: obj.maintenanceFeeOutstanding,
				otherFee: obj.otherFeeOutstanding,
				penalty:
					obj.penaltyOutstanding +
					insuranceTotalList.reduce((pre, cur) => {
						return pre + cur.penaltyOutstanding
					}, 0),
				interest:
					obj.interestOutstanding +
					insuranceTotalList.reduce((pre, cur) => {
						return pre + cur.interestOutstanding
					}, 0),
				total:
					obj.totalOutstanding +
					insuranceTotalList.reduce((pre, cur) => {
						return pre + cur.totalOutstanding
					}, 0)
			})

			this.repayDataSource = this.getOtherFeeDetails(this.repaymentDetails)
			let arr2 = [];
			if(this.insuranceRepaymentDetails && this.insuranceRepaymentDetails.length> 0){
				this.insuranceRepaymentDetails.map(item => {
					if(item.insuranceId == this.additionalLoanId) {
						arr2.push(item)
					}
				})
			}
			this.insuRepayDataSource = this.getOtherFeeDetails(arr2)
			let arr = [];
			if(this.emergencyRepaymentDetails && this.emergencyRepaymentDetails.length> 0){
				this.emergencyRepaymentDetails.map(item => {
					if(item.emergencyId == this.additionalLoanId) {
						arr.push(item)
					}
				})
			}
			this.emergencyRepayDataSource = this.getOtherFeeDetails(arr)
		})
	}
}
</script>
<style lang="less">
.p_list {
	text-align: center;
}
.link {
	cursor: pointer;
	color: #40a9ff;
}
.border-right {
	.ant-table-small.ant-table-bordered .ant-table-tbody > tr > td:last-child {
		border-right: 1px solid #e8e8e8;
	}
}
</style>
